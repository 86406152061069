import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  uuid: "",
  name: "",
  price_list: [
    {
      volume: "",
      weight: "",
      price: null,
      image: "",
      barcode: "",
    },
    {
      volume: "",
      weight: "",
      price: null,
      image: "",
      barcode: "",
    },
    {
      volume: "",
      weight: "",
      price: null,
      image: "",
      barcode: "",
    },
    {
      volume: "",
      weight: "",
      price: null,
      image: "",
      barcode: "",
    },
  ],
}
const product = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    /** Сброс state */
    reset_product(state) {
      Object.assign(state, initialState);
    },
    set_product(state, action) {
      state = action.payload
      return state
    },
    set_name(state, action) {
      state.name = action.payload
    },
    set_image(state, action) {
      if (state.price_list[action.payload.index].image !== undefined) {
        state.price_list[action.payload.index].image = action.payload.value
      }
    },
    set_volume(state, action) {
      if (state.price_list[action.payload.index].volume !== undefined) {
        state.price_list[action.payload.index].volume = action.payload.value
      }
    },
    set_weight(state, action) {
      if (state.price_list[action.payload.index].weight !== undefined) {
        state.price_list[action.payload.index].weight = action.payload.value
      }
    },
    set_price(state, action) {
      if (state.price_list[action.payload.index].price !== undefined) {
        state.price_list[action.payload.index].price = action.payload.value
      }
    },
    set_barcode(state, action) {
      if (state.price_list[action.payload.index].barcode !== undefined) {
        state.price_list[action.payload.index].barcode = action.payload.value
      }
    },
  },
});

export const {
  reset_product,
  set_product,
  set_name,
  set_image,
  set_volume,
  set_weight,
  set_price,
  set_barcode,
} = product.actions;

export default product.reducer;
